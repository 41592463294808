import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import "./Checkout.css";

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var emailRe =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRe.test(String(email).toLowerCase());
}

function validatePassword(password) {
  if (password.length < 6) {
    return "minimum password length of 6 characters";
  }
  return "";
}

function validateName(name) {
  return name.length < 2;
}

const useStyles = makeStyles((theme) => ({
  loading: {
    "&": { margin: theme.spacing(4) },
  },
  root: {
    "& > * > *": {
      margin: theme.spacing(1, 2, 1, 0),
    },
  },
}));

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const [cardError, setCardError] = useState("");
  const [checkedOut, setCheckedOut] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [intentToken, setIntentToken] = useState("");
  const [isCardValid, setIsCardValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPayable, setIsPayable] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [orderSummary, setOrderSummary] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [paymentIntentError, setPaymentIntentError] = useState("");
  const [triggerPayment, setTriggerPayment] = useState(false);
  const [updateOrderError, setUpdateOrderError] = useState("");

  useEffect(() => {
    if (!intentToken && !orderSummary) {
      const fetchOrder = async () => {
        setIsLoading(true);
        await fetch("/api/order/create_payment_intent", { method: "POST" })
          .then((res) => res.json())
          .then((data) => {
            if (data.intentToken && data.orderSummary) {
              setIntentToken(data.intentToken);
              setOrderSummary(data.orderSummary);
            } else if (data.error) {
              setPaymentIntentError(data.error);
            } else {
              setPaymentIntentError("An unknown error occurred");
            }
          });
        setIsLoading(false);
      };

      fetchOrder();
    }

    setIsPayable(
      stripe &&
        isCardValid &&
        email &&
        !emailError &&
        firstName &&
        lastName &&
        !firstNameError &&
        !lastNameError &&
        !passwordError &&
        password.length
    );
  }, [
    email,
    emailError,
    firstName,
    firstNameError,
    intentToken,
    isCardValid,
    lastName,
    lastNameError,
    orderSummary,
    password.length,
    passwordError,
    stripe,
  ]);

  useEffect(() => {
    const makePayment = async () => {
      // make the payment
      setIsPaying(true);
      const result = await stripe.confirmCardPayment(intentToken, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${firstName} ${lastName}`,
            email: email,
          },
        },
        receipt_email: email,
      });

      if (result.error) {
        setPaymentError(result.error);
        return;
      }

      if (result.paymentIntent.status === "succeeded") {
        // update order status
        await fetch("/api/order/paid", {
          headers: { "Content-Type": "application/json" },
          method: "POST",
        })
          .then((res) => res.json())
          .then((data) => {
            data.error ? setUpdateOrderError(data.error) : setCheckedOut(true);
          });
      }
      setIsPaying(false);
      props.wipeBadge();
    };

    if (triggerPayment) {
      makePayment();
    }
  }, [
    elements,
    email,
    firstName,
    intentToken,
    lastName,
    stripe,
    triggerPayment,
  ]);

  const handleSubmit = async (event) => {
    // avoid reloading the page
    event.preventDefault();

    // early exit is stripe is not loaded, although this is impossible...
    if (!stripe || !elements) {
      return;
    }

    setTriggerPayment(true);
  };

  return isLoading ? (
    <CircularProgress className={classes.loading} />
  ) : (
    <React.Fragment>
      <h2>Checkout</h2>
      {paymentIntentError && <h3> {paymentIntentError} </h3>}
      {isPaying && (
        <React.Fragment>
          <h3>Your payment is processing, please wait...</h3>
          <CircularProgress className={classes.loading} />
        </React.Fragment>
      )}
      {checkedOut && <h3>Your payment was successful!</h3>}
      {paymentError && <h3> {paymentError} </h3>}
      {updateOrderError && (
        <React.Fragment>
          <h3> {updateOrderError} </h3>
          <p>
            Your payment was processed, but we had trouble updating your order.
          </p>
          <p>
            You will get your photos, you just might not be able to see this
            order in your order history.
          </p>
        </React.Fragment>
      )}
      <div
        hidden={
          paymentIntentError ||
          isPaying ||
          paymentError ||
          updateOrderError ||
          checkedOut
        }
      >
        <Card className="checkoutReceipt" raised={false}>
          <CardContent>
            <h3>Order Summary</h3>
          </CardContent>
          <CardContent>
            <p>
              <b>Total Price</b>: $
              {Number.parseFloat(orderSummary.orderTotal * 0.01).toFixed(2)}
            </p>
            <p>
              <b>Number of Photos</b>: {orderSummary.numItems}
            </p>
          </CardContent>
          <div>
            <CardContent>
              <p>
                <b>Please Note:</b>
              </p>
              <p>
                In order to deliver your digital photo(s) and receipt, we
                require an email address.
              </p>
              <p>
                We will not store credit card information, only your email
                address and a record of your order, which you can view later.
              </p>
              <p>
                <i>
                  Expect your digital photo(s) to be delivered to the provided
                  email address within 24 hours of your purchase.
                </i>
              </p>
              <p>
                For any questions, please{" "}
                <b>
                  <a
                    style={{ color: "white" }}
                    href="mailto:sknappshotsphotography@outlook.com"
                  >
                    {" "}
                    reach out to me here{" "}
                  </a>
                </b>
                .
              </p>
            </CardContent>
          </div>
          <CardContent>
            <form
              autoComplete="off"
              className="checkoutForm"
              onSubmit={handleSubmit}
            >
              <FormGroup className={classes.root}>
                <FormGroup row>
                  <TextField
                    className="textField"
                    error={Boolean(emailError)}
                    helperText={
                      emailError ? "please enter a valid email address" : ""
                    }
                    label="email address"
                    margin="normal"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(!validateEmail(e.target.value));
                    }}
                    type="email"
                  />
                </FormGroup>
                <FormGroup row>
                  <TextField
                    className="textField"
                    error={firstNameError}
                    label="first name"
                    margin="normal"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFirstNameError(validateName(e.target.value));
                    }}
                  />
                  <TextField
                    className="textField"
                    error={lastNameError}
                    label="last name"
                    margin="normal"
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setLastNameError(validateName(e.target.value));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <CardElement
                    onChange={(event) => {
                      if (event.complete) {
                        setIsCardValid(true);
                        setCardError("");
                      } else if (event.error) {
                        setIsCardValid(false);
                        setCardError(event.error.message);
                      } else {
                        setCardError("");
                      }
                    }}
                  />
                  <div className="cardError">{cardError}</div>
                </FormGroup>
                <Button
                  color="primary"
                  disabled={!isPayable}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  Pay
                </Button>
              </FormGroup>
            </form>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

CheckoutForm.propTypes = {
  wipeBadge: PropTypes.func,
};

export default CheckoutForm;
